import { SideNavInterface } from '../../interfaces/side-nav.type';

export const ROUTES: SideNavInterface[] = [
    {
        path: 'dashboard',
        title: 'Dashboard',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'dashboard',
        submenu: []
    },
    {
        path: '',
        title: 'Terminal',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'laptop',
        submenu: [
            {
                path: 'terminal/list',
                title: 'List',
                iconType: '',
                icon: '',
                iconTheme: '',
                submenu: []
            },
            {
                path: 'terminal/monitor',
                title: 'Monitor',
                iconType: '',
                icon: '',
                iconTheme: '',
                submenu: []
            },

        ]
    },
    {
        path: '',
        title: 'Publishing',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'send',
        submenu: [
            {
                path: 'publishing/program',
                title: 'Program',
                iconType: '',
                icon: '',
                iconTheme: '',
                submenu: []
            },

        ]
    },
    {
        path: '',
        title: 'Setting',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'setting',
        submenu: [
            {
                path: 'setting/version',
                title: 'Version',
                iconType: '',
                icon: '',
                iconTheme: '',
                submenu: []
            },
            {
                path: 'setting/log',
                title: 'Log',
                iconType: '',
                icon: '',
                iconTheme: '',
                submenu: []
            },

        ]
    },
    {
        path: 'notice',
        title: 'Notice',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'windows',
        submenu: []
    }
]